.current-track-container {
    display: flex;
    margin-left: 40px;
}

.playing {
    transform: translateX(-50%);
}

.bar {
    display: inline-block;
    width: 10px;
    height: 1px;
    background: black;
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    margin-right: 4px;
}

.bar:last-of-type {
    margin-right: 0px;
}

.n1 {
    animation-delay: 0.5s;
}

.n2 {
    animation-delay: 0.2s;
}

.n3 {
    animation-delay: .4s;
}

.n4 {
    animation-delay: 0.9s;
}

.n5 {
    animation-delay: .3s;
}


@keyframes pulse {
    0% {
        height: 10px;
        margin-top: 0;
    }

    10% {
        height: 40px;
        margin-top: -40px;
    }

    50% {
        height: 20px;
        margin-top: -20px;
    }

    60% {
        height: 30px;
        margin-top: -30px;
    }

    80% {
        height: 50px;
        margin-top: -60px;
    }

    100% {
        height: 10px;
        margin-top: 0;
    }
}